import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Card, Spin, message, Modal, Button } from 'antd';
import { WalletOutlined, TrophyOutlined, DollarOutlined, QuestionCircleOutlined, PayCircleOutlined, MoneyCollectOutlined } from '@ant-design/icons';
import { TabBar } from '../components/TabBar';
import axios from 'axios';

// 创建一个配置好的 axios 实例
const api = axios.create({
  baseURL: 'http://47.120.64.160:8080',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

const PageContainer = styled.div`
  padding: 16px;
  padding-bottom: 60px;
  background-color: #f5f5f5;
  min-height: 100vh;
`;

const HeaderCard = styled.div`
  background: linear-gradient(135deg, #1890ff 0%, #096dd9 100%);
  border-radius: 20px;
  padding: 28px;
  color: white;
  margin-bottom: 24px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, transparent 45%, rgba(255,255,255,0.1) 48%, rgba(255,255,255,0.1) 52%, transparent 55%);
    background-size: 200% 200%;
    animation: shine 3s infinite;
  }

  @keyframes shine {
    to {
      background-position: 200% 200%;
    }
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
  z-index: 10;

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }

  .rules-icon {
    position: relative;
    z-index: 11;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 12px;
  padding: 16px;
  margin-bottom: 20px;

  @media (max-width: 375px) {
    grid-template-columns: repeat(2, 1fr);  // 在小屏幕上显示两列
    gap: 8px;
  }
`;

const StatCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 16px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .icon {
    font-size: 24px;
    color: #1890ff;
    margin-bottom: 4px;
  }

  .value {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    line-height: 1.2;
  }

  .label {
    font-size: 12px;
    color: #666;
    margin-top: 4px;
  }

  @media (max-width: 375px) {
    padding: 12px 8px;
    
    .icon {
      font-size: 20px;
    }

    .value {
      font-size: 16px;
    }

    .label {
      font-size: 11px;
    }
  }
`;

const DetailCard = styled(Card)`
  border-radius: 12px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

  .title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
    color: #1890ff;
  }

  .item {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid #f0f0f0;
    
    &:last-child {
      border-bottom: none;
    }
    
    .label {
      color: #666;
    }
    
    .value {
      font-weight: 500;
      color: #262626;
    }
  }
`;

const BonusHighlight = styled.div`
  background: linear-gradient(135deg, #1890ff 0%, #096dd9 100%);
  border-radius: 20px;
  padding: 32px;
  text-align: center;
  color: white;
  margin-bottom: 24px;
  box-shadow: 0 8px 24px rgba(24, 144, 255, 0.25);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%);
    animation: rotate 15s linear infinite;
  }

  .bonus-label {
    font-size: 18px;
    opacity: 0.9;
    margin-bottom: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .bonus-value {
    font-size: 48px;
    font-weight: bold;
    text-shadow: 0 2px 4px rgba(0,0,0,0.1);
    
    .currency {
      font-size: 32px;
      margin-right: 8px;
      opacity: 0.9;
    }
  }

  .bonus-description {
    margin-top: 12px;
    font-size: 14px;
    opacity: 0.8;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const TotalStatsCard = styled(Card)`
  border-radius: 12px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  background: linear-gradient(135deg, #f6f6f6 0%, #ffffff 100%);

  .title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
    color: #1890ff;
  }

  .stats-row {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid #f0f0f0;
    
    &:last-child {
      border-bottom: none;
    }
    
    .label {
      color: #666;
    }
    
    .value {
      font-weight: 500;
      color: #262626;
      
      &.highlight {
        color: #1890ff;
        font-weight: bold;
      }
    }
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    
    .ant-modal-header {
      border-radius: 12px 12px 0 0;
      background: #f8f9fa;
      padding: 16px 24px;
      
      .ant-modal-title {
        font-weight: bold;
        color: #1890ff;
        font-size: 18px;
      }
    }
    
    .ant-modal-body {
      padding: 24px;
      
      p {
        margin-bottom: 16px;
        line-height: 1.6;
      }
      
      ul {
        margin-bottom: 16px;
        padding-left: 20px;
        
        li {
          margin-bottom: 8px;
          line-height: 1.6;
        }
      }
      
      strong {
        color: #1890ff;
      }
    }
  }
`;

interface TotalStats {
  totalYuanbao: number;
  totalAmount: number;
}

interface EmployeeStats {
  integralValue: number;
  regionName: string;
  percentile: number;
  baseYuanbao: number;
  multiplier: number;
  totalYuanbao: number;
}

export const BonusEstimate: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [totalStats, setTotalStats] = useState<TotalStats>({
    totalYuanbao: 0,
    totalAmount: 0
  });
  const [personalStats, setPersonalStats] = useState<EmployeeStats | null>(null);
  const [showRules, setShowRules] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const employeeStr = localStorage.getItem('employee');
      if (!employeeStr) {
        message.error('未找到员工信息');
        setLoading(false);
        return;
      }

      const employee = JSON.parse(employeeStr);
      try {
        console.group('=== 奖金预估数据请求 ===');
        
        // 获取总体统计数据
        console.log('正在请求总体统计数据...');
        const totalResponse = await api.get('/api/stats/total');
        console.log('总体统计数据请求信息:', {
          url: '/api/stats/total',
          headers: api.defaults.headers,
          response: totalResponse
        });

        if (totalResponse.data) {
          console.log('总体统计数据:', totalResponse.data);
          setTotalStats(totalResponse.data);
        }

        // 获取个人统计数据
        console.log('正在请求个人统计数据...');
        const personalResponse = await api.get(`/api/stats/employee`, {
          params: { idNumber: employee.idNumber }
        });
        console.log('个人统计数据请求信息:', {
          url: `/api/stats/employee`,
          params: { idNumber: employee.idNumber },
          headers: api.defaults.headers,
          response: personalResponse
        });

        if (personalResponse.data) {
          console.log('个人统计数据:', personalResponse.data);
          setPersonalStats(personalResponse.data);
        }

        console.groupEnd();
      } catch (error) {
        console.group('=== 奖金预估数据请求错误 ===');
        console.error('请求错误:', error);
        if (axios.isAxiosError(error)) {
          console.error('请求配置:', error.config);
          console.error('响应数据:', error.response?.data);
          console.error('响应状态:', error.response?.status);
          console.error('响应头:', error.response?.headers);
        }
        console.groupEnd();
        
        message.error('获取数据失败');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <PageContainer>
        <div style={{ textAlign: 'center', marginTop: '40px' }}>
          <Spin size="large" tip="加载中..." />
        </div>
      </PageContainer>
    );
  }

  // 计算单个元宝的价值
  const yuanbaoPrice = totalStats.totalYuanbao > 0 
    ? Number((totalStats.totalAmount / totalStats.totalYuanbao).toFixed(2)) 
    : 0;

  // 计算预估奖金
  const estimatedBonus = personalStats 
    ? Number((personalStats.totalYuanbao * yuanbaoPrice).toFixed(2))
    : 0;

  return (
    <PageContainer>
      <HeaderCard>
        <HeaderTitle>
          <h1>奖金预估</h1>
          <QuestionCircleOutlined 
            className="rules-icon"
            style={{ 
              fontSize: '20px', 
              cursor: 'pointer',
              color: 'white',
              transition: 'all 0.3s',
              position: 'relative',
              zIndex: 11
            }}
            onClick={() => setShowRules(true)}
          />
        </HeaderTitle>
      </HeaderCard>

      <BonusHighlight>
        <div className="bonus-label">预估奖金</div>
        <div className="bonus-value">
          <span className="currency">¥</span>
          {estimatedBonus.toLocaleString()}
        </div>
        <div className="bonus-description">
          实际奖金可能会随市场表现浮动
        </div>
      </BonusHighlight>

      <StatsContainer>
        <StatCard>
          <MoneyCollectOutlined className="icon" />
          <div className="value">
            {totalStats.totalAmount.toLocaleString()}
          </div>
          <div className="label">奖金池(元)</div>
        </StatCard>

        <StatCard>
          <TrophyOutlined className="icon" />
          <div className="value">
            {totalStats.totalYuanbao.toLocaleString()}
          </div>
          <div className="label">总元宝数</div>
        </StatCard>

        <StatCard>
          <PayCircleOutlined className="icon" />
          <div className="value">
            ¥{yuanbaoPrice.toFixed(2)}
          </div>
          <div className="label">元宝单价(元)</div>
        </StatCard>
      </StatsContainer>

      <TotalStatsCard>
        <div className="title">总体统计</div>
        <div className="stats-row">
          <span className="label">总奖金池</span>
          <span className="value highlight">¥ {totalStats.totalAmount.toLocaleString()}</span>
        </div>
        <div className="stats-row">
          <span className="label">总元宝数</span>
          <span className="value">{totalStats.totalYuanbao.toLocaleString()}</span>
        </div>
        <div className="stats-row">
          <span className="label">元宝单价</span>
          <span className="value">¥ {yuanbaoPrice}</span>
        </div>
      </TotalStatsCard>

      <DetailCard>
        <div className="title">个人明细</div>
        <div className="item">
          <span className="label">当前积分</span>
          <span className="value">{personalStats?.integralValue.toLocaleString() || 0}</span>
        </div>
        <div className="item">
          <span className="label">所属组别</span>
          <span className="value">{personalStats?.regionName || '-'}</span>
        </div>
        <div className="item">
          <span className="label">组内排名</span>
          <span className="value">前{((personalStats?.percentile || 0) * 100).toFixed(1)}%</span>
        </div>
        <div className="item">
          <span className="label">个人元宝数</span>
          <span className="value">{personalStats?.totalYuanbao || 0}</span>
        </div>
        <div className="item">
          <span className="label">元宝基数</span>
          <span className="value">{personalStats?.baseYuanbao || 0}</span>
        </div>
        <div className="item">
          <span className="label">膨胀系数</span>
          <span className="value">{personalStats?.multiplier.toFixed(1) || '1.0'}x</span>
        </div>
      </DetailCard>

      <StyledModal
        title="年终挑战赛活动细则"
        open={showRules}
        onCancel={() => setShowRules(false)}
        width={600}
        maskClosable={true}
        centered
        footer={[
          <Button key="close" type="primary" onClick={() => setShowRules(false)}>
            我知道了
          </Button>
        ]}
      >
        <div style={{ maxHeight: '70vh', overflow: 'auto' }}>
          <p><strong>核心概念：元宝</strong></p>
          <p>各角色通过业绩获取元宝，最终将元宝兑换为奖金。</p>

          <p><strong>业绩计算规则：</strong></p>
          <p>蓝钻值 = 素金业绩/5 + 珠宝业绩</p>
          <ul>
            <li>换款规则：
              <ul>
                <li>宝换宝：计算差额</li>
                <li>素换宝：按回收价计算</li>
                <li>折扣需不低于底价</li>
              </ul>
            </li>
          </ul>

          <p><strong>元宝基数获取规则：</strong></p>
          <ul>
            <li>红色战区：
              <ul>
                <li>0-5万蓝钻：每2000蓝钻 = 1元宝</li>
                <li>5万以上：每1000蓝钻 = 1元宝</li>
              </ul>
            </li>
            <li>蓝色战区：
              <ul>
                <li>0-4万蓝钻：每2000蓝钻 = 1元宝</li>
                <li>4万以上：每1000蓝钻 = 1元宝</li>
              </ul>
            </li>
            <li>紫色战区：
              <ul>
                <li>0-3万蓝钻：每2000蓝钻 = 1元宝</li>
                <li>3万以上：每1000蓝钻 = 1元宝</li>
              </ul>
            </li>
          </ul>

          <p><strong>区域排名膨胀系数：</strong></p>
          <ul>
            <li>前10%排名：
              <ul>
                <li>紫色战区：2.0倍</li>
                <li>蓝色战区：1.5倍</li>
                <li>红色战区：1.3倍</li>
              </ul>
            </li>
            <li>前10%-20%排名：
              <ul>
                <li>紫色战区：1.5倍</li>
                <li>蓝色战区：1.3倍</li>
                <li>红色战区：1.2倍</li>
              </ul>
            </li>
            <li>前20%-30%排名：
              <ul>
                <li>紫色战区：1.3倍</li>
                <li>蓝色战区：1.2倍</li>
                <li>红色战区：1.1倍</li>
              </ul>
            </li>
          </ul>

          <p><strong>额外奖励：</strong></p>
          <p>活动前10天：各组别蓝钻数量第一名可额外获得10个元宝</p>

          <p><strong>奖金池规则：</strong></p>
          <ul>
            <li>初始奖金池：10,000元</li>
            <li>增长规则：
              <ul>
                <li>参赛选手总蓝钻每增加10,000分，奖金池增加100元</li>
                <li>当奖金池达到50,000元后，每增加10,000分，奖金池增加150元</li>
                <li>当奖金池达到100,000元后，每增加10,000分，奖金池增加200元</li>
              </ul>
            </li>
          </ul>

          <p><strong>元宝计算与兑现：</strong></p>
          <ul>
            <li>元宝获得 = 元宝基数 × 膨胀系数</li>
            <li>元宝价值 = 奖金池总额 ÷ 全公司总元宝数量（计入膨胀系数后）</li>
            <li>最终奖金 = 元宝获得数量 × 元宝单价</li>
          </ul>

          <p><strong>最低元宝要求：</strong></p>
          <p>活动结束时：</p>
          <ul>
            <li>红色战区：低于20个元宝作废</li>
            <li>蓝色战区：低于15个元宝作废</li>
            <li>紫色战区：低于10个元宝作废</li>
          </ul>

          <p style={{ color: '#666', marginTop: '24px', borderTop: '1px solid #f0f0f0', paddingTop: '16px' }}>
            * 本活动最终解释权归公司总部运营中心所有
          </p>
        </div>
      </StyledModal>

      <TabBar />
    </PageContainer>
  );
}; 