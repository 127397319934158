import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Login } from '../pages/Login';
import { Dashboard } from '../pages/Dashboard';
import { Profile } from '../pages/Profile';
import { Ranking } from '../pages/Ranking';
import { BonusEstimate } from '../pages/BonusEstimate';
import { Loading } from '../components/Loading';
import { StoreRanking } from '../pages/StoreRanking';

const PrivateRoute: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const employeeStr = localStorage.getItem('employee');
  if (!employeeStr) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

export const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/ranking"
            element={
              <PrivateRoute>
                <Ranking />
              </PrivateRoute>
            }
          />
          <Route
            path="/bonus"
            element={
              <PrivateRoute>
                <BonusEstimate />
              </PrivateRoute>
            }
          />
          <Route
            path="/store-ranking"
            element={
              <PrivateRoute>
                <StoreRanking />
              </PrivateRoute>
            }
          />
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}; 