import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Spin, message } from 'antd';
import { TrophyOutlined, CalendarOutlined } from '@ant-design/icons';
import { TabBar } from '../components/TabBar';
import { authApi } from '../api/auth';
import type { StoreRankingData } from '../types/auth';

const PageContainer = styled.div`
  padding: 16px;
  padding-bottom: 60px;
  background-color: #f5f5f5;
  min-height: 100vh;
`;

const HeaderCard = styled.div`
  background: linear-gradient(135deg, #1890ff 0%, #096dd9 100%);
  border-radius: 12px;
  padding: 20px;
  color: white;
  margin-bottom: 16px;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  .anticon {
    font-size: 24px;
    margin-right: 8px;
  }

  h1 {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }
`;

const UpdateTime = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  opacity: 0.8;

  .anticon {
    margin-right: 4px;
  }
`;

const RankingList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const RankingCard = styled.div<{ rank: number }>`
  background: white;
  border-radius: 12px;
  padding: 16px;
  border: 2px solid;
  border-color: ${props => {
    switch (props.rank) {
      case 1: return '#ffd700';
      case 2: return '#c0c0c0';
      case 3: return '#cd7f32';
      default: return '#f0f0f0';
    }
  }};
  box-shadow: 0 2px 8px ${props => {
    switch (props.rank) {
      case 1: return 'rgba(255, 215, 0, 0.1)';
      case 2: return 'rgba(192, 192, 192, 0.1)';
      case 3: return 'rgba(205, 127, 50, 0.1)';
      default: return 'rgba(0, 0, 0, 0.05)';
    }
  }};
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.01);
  }
`;

const RankingContent = styled.div`
  display: flex;
  align-items: center;
`;

const RankNumberBadge = styled.div<{ rank: number }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  margin-right: 16px;
  background-color: ${props => {
    switch (props.rank) {
      case 1: return '#ffd700';
      case 2: return '#c0c0c0';
      case 3: return '#cd7f32';
      default: return '#d9d9d9';
    }
  }};
`;

const StoreInfo = styled.div`
  flex: 1;
`;

const StoreName = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #333;
`;

const Points = styled.div<{ rank: number }>`
  text-align: right;

  .points-value {
    font-size: 18px;
    font-weight: bold;
    color: ${props => {
      switch (props.rank) {
        case 1: return '#ffd700';
        case 2: return '#c0c0c0';
        case 3: return '#cd7f32';
        default: return '#666';
      }
    }};
  }

  .points-label {
    font-size: 12px;
    color: #999;
    margin-top: 4px;
  }
`;

export const StoreRanking: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [rankings, setRankings] = useState<StoreRankingData[]>([]);
  const [lastUpdateDate] = useState(new Date().toLocaleString());

  useEffect(() => {
    const fetchRankings = async () => {
      try {
        console.log('开始获取店铺排名...');
        const response = await authApi.getStoreRankings();
        console.log('获取到的店铺排名响应:', response);
        
        if (response.code === 200 && response.data) {
          console.log('店铺排名数据:', response.data);
          const rankingsData = Array.isArray(response.data) ? response.data : [];
          setRankings(rankingsData);
        } else {
          console.error('获取排名失败:', {
            code: response.code,
            message: response.message,
            response
          });
          message.error(response.message || '获取排名失败');
        }
      } catch (error) {
        console.error('获取店铺排名出错:', error);
        if (error instanceof Error) {
          console.error('错误详情:', {
            name: error.name,
            message: error.message,
            stack: error.stack
          });
        }
        message.error('获取排名失败，请稍后重试');
      } finally {
        setLoading(false);
      }
    };

    fetchRankings();
  }, []);

  if (loading) {
    return (
      <PageContainer>
        <div style={{ textAlign: 'center', marginTop: '40px' }}>
          <Spin size="large" tip="加载中..." />
        </div>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <HeaderCard>
        <HeaderTitle>
          <TrophyOutlined />
          <h1>店面排行榜</h1>
        </HeaderTitle>
        <UpdateTime>
          <CalendarOutlined />
          <span>最后更新：{lastUpdateDate}</span>
        </UpdateTime>
      </HeaderCard>

      <RankingList>
        {rankings.map((store) => (
          <RankingCard key={store.storeId} rank={store.ranking}>
            <RankingContent>
              <RankNumberBadge rank={store.ranking}>
                {store.ranking}
              </RankNumberBadge>
              <StoreInfo>
                <StoreName>{store.storeName}</StoreName>
              </StoreInfo>
              <Points rank={store.ranking}>
                <div className="points-value">
                  {store.totalIntegral.toLocaleString()}
                </div>
                <div className="points-label">积分</div>
              </Points>
            </RankingContent>
          </RankingCard>
        ))}
      </RankingList>

      <TabBar />
    </PageContainer>
  );
}; 