import axios, { AxiosError } from 'axios';
import type { RegionsResponse, LoginRequest, LoginResponse, UpdateProfileRequest, ApiResponse, AvatarInfo, AvatarResponse, StoreInfo, RegionInfo, EmployeeDetailResponse, RegionNameResponse, StoreNameResponse, RankingEmployee, RankingResponse, StoreRankingData, StoreRankingResponse } from '../types/auth';
/// <reference types="vite/client" />
const BASE_URL = 'http://47.120.64.160:8080/api';

// 添加类型定义
interface PersonalRankResponse {
  code: number;
  message: string;
  data: {
    rank: number;
    total: number;
    integralValue: number;
    percentage: string;
    group?: string;
    groupPercentage?: string;
  } | null;
}

// 添加员工积分统计接口的类型定义
export interface EmployeeStats {
  integralValue: number;
  regionName: string;
  percentile: number;
  baseYuanbao: number;
  multiplier: number;
  totalYuanbao: number;
}

export interface EmployeeStatsResponse {
  code: number;
  message: string;
  data: EmployeeStats | null;
}

interface ImportMetaEnv {
  VITE_APP_API_BASE_URL: string;
}

// 添加修改密码的接口
export interface UpdatePasswordRequest {
  idNumber: string;
  oldPassword: string;
  newPassword: string;
}

interface UpdatePasswordResponse {
  code: number;
  message: string;
  data: boolean | null;
}

export const authApi = {
  // 添加获取区域列表的方法
  async getRegions(): Promise<RegionsResponse> {
    try {
      console.log('调用获取区域列表 API...');
      const response = await axios.get(`${BASE_URL}/regions`);
      console.log('区域列表 API 响应:', response.data);
      return response.data;
    } catch (error) {
      console.error('获取区域列表失败:', error);
      const axiosError = error as AxiosError<RegionsResponse>;
      if (axiosError.response?.data) {
        return axiosError.response.data;
      }
      return {
        code: 500,
        message: '网络错误，请稍后重试',
        data: null
      };
    }
  },
  async login(params: LoginRequest): Promise<LoginResponse> {
    try {
      const response = await axios.post(`${BASE_URL}/employees/login`, params);
      console.log('Login response:', response.data);
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<LoginResponse>;
      console.error('Login error:', axiosError.response?.data || error);
      if (axiosError.response?.data) {
        return axiosError.response.data;
      }
      return {
        code: 500,
        message: '网络错误，请稍后重试',
        data: undefined
      };
      
    }
  },

  async updatePassword(params: UpdatePasswordRequest): Promise<UpdatePasswordResponse> {
    try {
      const response = await axios.put(`${BASE_URL}/employees/password`, null, {
        params: {
          idNumber: params.idNumber,
          oldPassword: params.oldPassword,
          newPassword: params.newPassword
        }
      });
      return response.data;
    } catch (error) {
      console.error('修改密码失败:', error);
      const axiosError = error as AxiosError<UpdatePasswordResponse>;
      if (axiosError.response?.data) {
        return axiosError.response.data;
      }
      return {
        code: 500,
        message: '网络错误，请稍后重试',
        data: null
      };
    }
  },

  async updateProfile(params: UpdateProfileRequest): Promise<ApiResponse> {
    try {
      const response = await axios.post(`${BASE_URL}/employees/update-profile`, params);
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<ApiResponse>;
      if (axiosError.response?.data) {
        return axiosError.response.data;
      }
      return {
        code: 500,
        message: '网络错误，请稍后重试',
        data: null
      };
    }
  },

  async uploadAvatar(idNumber: string, file: File): Promise<AvatarResponse> {
    try {
      const formData = new FormData();
      formData.append('idNumber', idNumber);
      formData.append('file', file);

      const response = await axios.post(`${BASE_URL}/employees/avatar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<AvatarResponse>;
      if (axiosError.response?.data) {
        return axiosError.response.data;
      }
      return {
        code: 500,
        message: '网络错误，请稍后重试',
        data: null
      };
    }
  },

  async getAvatar(idNumber: string): Promise<AvatarResponse> {
    try {
      const response = await axios.get(`${BASE_URL}/employees/avatar`, {
        params: { idNumber }
      });
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<AvatarResponse>;
      if (axiosError.response?.data) {
        return axiosError.response.data;
      }
      return {
        code: 500,
        message: '网络错误，请稍后重试',
        data: null
      };
    }
  },

  async getEmployeeDetail(idNumber: string): Promise<EmployeeDetailResponse> {
    try {
      const response = await axios.get(`${BASE_URL}/employees/detail`, {
        params: { idNumber }
      });
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<EmployeeDetailResponse>;
      if (axiosError.response?.data) {
        return axiosError.response.data;
      }
      return {
        code: 500,
        message: '网络错误，请稍后重试',
        data: null
      };
    }
  },

  async getRegionName(id: string): Promise<RegionNameResponse> {
    try {
      const response = await axios.get(`${BASE_URL}/regions/${id}/name`);
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<RegionNameResponse>;
      if (axiosError.response?.data) {
        return axiosError.response.data;
      }
      return {
        code: 500,
        message: '网络错误，请稍后重试',
        data: null
      };
    }
  },

  async getStoreName(id: string): Promise<StoreNameResponse> {
    try {
      const response = await axios.get(`${BASE_URL}/stores/${id}/name`);
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<StoreNameResponse>;
      if (axiosError.response?.data) {
        return axiosError.response.data;
      }
      return {
        code: 500,
        message: '网络错误，请稍后重试',
        data: null
      };
    }
  },

  async getEmployeeRanking(): Promise<RankingResponse> {
    try {
      console.log('Calling ranking API...');
      const response = await axios.get(`${BASE_URL}/employees/ranking`);
      console.log('API Response:', response.data);
      return response.data;
    } catch (error) {
      console.error('API Error:', error);
      const axiosError = error as AxiosError<RankingResponse>;
      if (axiosError.response?.data) {
        return axiosError.response.data;
      }
      return {
        code: 500,
        message: '网络错误，请稍后重试',
        data: null
      };
    }
  },

  async getPersonalRank(idNumber: string): Promise<PersonalRankResponse> {
    try {
      const response = await axios.get(`${BASE_URL}/employees/rank`, {
        params: { idNumber }
      });
      
      if (response.data?.data) {
        const data = response.data.data;
        return {
          code: response.data.code,
          message: response.data.message,
          data: {
            rank: data.rank,
            total: data.total,
            integralValue: data.integralValue,
            percentage: data.percentage,
            group: data.group || 'C',
            groupPercentage: data.groupPercentage
          }
        };
      }
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<PersonalRankResponse>;
      if (axiosError.response?.data) {
        return axiosError.response.data;
      }
      return {
        code: 500,
        message: '网络错误，请稍后重试',
        data: null
      };
    }
  },

  async getStoreRankings(): Promise<StoreRankingResponse> {
    try {
      console.log('开始请求店铺排名数据...');
      const response = await axios.get(`${BASE_URL}/stats/store-rankings`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
      console.log('店铺排名响应数据:', response);
      
      if (response.data && Array.isArray(response.data)) {
        return {
          code: 200,
          message: 'success',
          data: response.data as StoreRankingData[]
        };
      }
      
      return response.data;
    } catch (error) {
      console.error('获取店铺排名失败:', error);
      const axiosError = error as AxiosError<StoreRankingResponse>;
      if (axiosError.response) {
        console.error('错误响应:', {
          status: axiosError.response.status,
          data: axiosError.response.data,
          headers: axiosError.response.headers
        });
      }
      if (axiosError.response?.data) {
        return axiosError.response.data;
      }
      return {
        code: 500,
        message: '网络错误，请稍后重试',
        data: null
      };
    }
  },

  async getEmployeeStats(idNumber: string): Promise<EmployeeStatsResponse> {
    try {
      console.log('获取员工积分统计...');
      const response = await axios.get(`${BASE_URL}/stats/employee`, {
        params: { idNumber }
      });
      console.log('员工积分统计原始响应:', response);

      // 如果直接返回了数据对象（没有 code 和 message 包装）
      if (response.data && !response.data.code) {
        return {
          code: 200,
          message: 'success',
          data: {
            integralValue: response.data.integralValue,
            regionName: response.data.regionName,
            percentile: response.data.percentile,
            baseYuanbao: response.data.baseYuanbao,
            multiplier: response.data.multiplier,
            totalYuanbao: response.data.totalYuanbao
          }
        };
      }

      return response.data;
    } catch (error) {
      console.error('获取员工积分统计失败:', error);
      const axiosError = error as AxiosError<EmployeeStatsResponse>;
      if (axiosError.response?.data) {
        return axiosError.response.data;
      }
      return {
        code: 500,
        message: '网络错误，请稍后重试',
        data: null
      };
    }
  }
}; 