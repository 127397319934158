import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { TrophyOutlined, CalendarOutlined, LoadingOutlined, TeamOutlined, RiseOutlined } from '@ant-design/icons';
import { TabBar } from '../components/TabBar';
import { authApi } from '../api/auth';
import type { RankingEmployee, Region } from '../types/auth';
import { message, Spin, Select } from 'antd';

const PageContainer = styled.div`
  padding: 16px;
  padding-bottom: 60px;
  background-color: #f5f5f5;
  min-height: 100vh;
`;

const HeaderCard = styled.div`
  background: linear-gradient(135deg, #1890ff 0%, #096dd9 100%);
  border-radius: 12px;
  padding: 20px;
  color: white;
  margin-bottom: 16px;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  .anticon {
    font-size: 24px;
    margin-right: 8px;
  }

  h1 {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }
`;

const UpdateTime = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  opacity: 0.8;

  .anticon {
    margin-right: 4px;
  }
`;

const RankingList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const RankingCard = styled.div<{ rank: number }>`
  background: white;
  border-radius: 12px;
  padding: 16px;
  border: 2px solid;
  border-color: ${props => {
    switch (props.rank) {
      case 1: return '#ffd700';
      case 2: return '#c0c0c0';
      case 3: return '#cd7f32';
      default: return '#f0f0f0';
    }
  }};
  box-shadow: 0 2px 8px ${props => {
    switch (props.rank) {
      case 1: return 'rgba(255, 215, 0, 0.1)';
      case 2: return 'rgba(192, 192, 192, 0.1)';
      case 3: return 'rgba(205, 127, 50, 0.1)';
      default: return 'rgba(0, 0, 0, 0.05)';
    }
  }};
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.01);
  }
`;

const RankingContent = styled.div`
  display: flex;
  align-items: center;
`;

const RankNumberBadge = styled.div<{ rank: number }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  margin-right: 16px;
  background-color: ${props => {
    switch (props.rank) {
      case 1: return '#ffd700';
      case 2: return '#c0c0c0';
      case 3: return '#cd7f32';
      default: return '#d9d9d9';
    }
  }};
`;

const UserInfo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  object-fit: cover;
  background-color: #f0f0f0;
`;

const UserDetails = styled.div`
  margin-left: 12px;
  flex: 1;
`;

const UserName = styled.h3`
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  color: #333;
`;

const ShopInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  gap: 8px;
`;

const ShopName = styled.span`
  font-size: 13px;
  color: #666;
  display: flex;
  align-items: center;
  
  &::after {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #d9d9d9;
    margin: 0 8px;
  }
`;

const RegionName = styled.span<{ regionName?: string }>`
  font-size: 13px;
  color: #666;
  background: ${props => {
    const group = props.regionName?.match(/^[ABC]组$/)?.[0] as GroupType | undefined;
    return group ? `${GROUP_COLORS[group]}10` : '#f5f5f5';
  }};
  color: ${props => {
    const group = props.regionName?.match(/^[ABC]组$/)?.[0] as GroupType | undefined;
    return group ? GROUP_COLORS[group] : '#666';
  }};
  padding: 2px 8px;
  border-radius: 4px;
`;

const Points = styled.div<{ rank: number }>`
  text-align: right;

  .points-value {
    font-size: 18px;
    font-weight: bold;
    color: ${props => {
      switch (props.rank) {
        case 1: return '#ffd700';
        case 2: return '#c0c0c0';
        case 3: return '#cd7f32';
        default: return '#666';
      }
    }};
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .points-label {
    font-size: 12px;
    color: #999;
    margin-top: 4px;
  }
`;

const MaskedValue = styled.span`
  font-family: monospace;
  letter-spacing: 1px;
  color: #999;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
`;

const AvatarFallback = styled.div<{ rank: number }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: ${props => {
    switch (props.rank) {
      case 1: return '#ffd700';
      case 2: return '#c0c0c0';
      case 3: return '#cd7f32';
      default: return '#f0f0f0';
    }
  }};
  color: ${props => props.rank <= 3 ? 'white' : '#666'};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
`;

const RegionSelector = styled.div`
  margin: 16px 0;
  background: white;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
`;

const PersonalRankCard = styled.div`
  background: linear-gradient(135deg, #1890ff 0%, #096dd9 100%);
  border-radius: 16px;
  padding: 24px;
  color: white;
  margin-bottom: 20px;
  text-align: center;
`;

const RankTitle = styled.div`
  font-size: 16px;
  opacity: 0.9;
  margin-bottom: 16px;
`;

const RankNumber = styled.div`
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 24px;
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
`;

const StatCard = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 16px;
  
  .icon {
    font-size: 24px;
    margin-bottom: 8px;
  }
  
  .value {
    font-size: 20px;
    font-weight: bold;
    margin: 4px 0;
  }
  
  .label {
    font-size: 12px;
    opacity: 0.8;
  }
`;

const RankingItem: React.FC<{
  employee: RankingEmployee;
  index: number;
  formatPoints: (points: number | undefined, rank: number, selectedGroup: string) => JSX.Element;
}> = ({ employee, index, formatPoints }) => {
  console.log('渲员工数据:', employee);

  const [avatarUrl, setAvatarUrl] = useState('/images/default-avatar.svg');

  useEffect(() => {
    const fetchAvatar = async () => {
      if (!employee.profile) {
        return;
      }

      try {
        const response = await authApi.getAvatar(employee.id_number);
        if (response.code === 200 && response.data?.url) {
          setAvatarUrl(response.data.url);
        }
      } catch (error) {
        console.error('Failed to fetch avatar:', error);
      }
    };

    fetchAvatar();
  }, [employee.profile, employee.id_number]);

  return (
    <RankingCard 
      key={employee.id || index} 
      rank={employee.rank}
    >
      <RankingContent>
        <RankNumberBadge rank={employee.rank}>
          {employee.rank}
        </RankNumberBadge>
        <UserInfo>
          {employee.profile ? (
            <Avatar 
              src={avatarUrl}
              alt={employee.username}
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.src = '/images/default-avatar.svg';
              }}
            />
          ) : (
            <AvatarFallback rank={employee.rank}>
              {employee.username?.[0] || '?'}
            </AvatarFallback>
          )}
          <UserDetails>
            <UserName>{employee.username || '未知用户'}</UserName>
            <ShopInfo>
              {/* <ShopName>{employee.storeName}</ShopName> */}
              <ShopName>**店铺</ShopName>
              <RegionName regionName={employee.regionName}>
                {GROUP_NAMES[employee.regionName as GroupType] || employee.regionName}
              </RegionName>
            </ShopInfo>
          </UserDetails>
        </UserInfo>
        <Points rank={employee.rank}>
          <div className="points-value">
            {formatPoints(employee.integralValue, employee.rank, '全部')}
          </div>
          <div className="points-label">元宝</div>
        </Points>
      </RankingContent>
    </RankingCard>
  );
};

// 定义组别类型
type GroupType = 'A组' | 'B组' | 'C组';

// 添加组别名称的映射
const GROUP_NAMES: Record<GroupType, string> = {
  'A组': '红色战区',
  'B组': '蓝色战区',
  'C组': '紫色战区'
};

// 添加组别颜色的映射
const GROUP_COLORS: Record<GroupType, string> = {
  'A组': '#ff4d4f',  // 红色
  'B组': '#1890ff',  // 蓝色
  'C组': '#722ed1'   // 紫色
};

export const Ranking: React.FC = () => {
  const [rankings, setRankings] = useState<RankingEmployee[]>([]);
  const [loading, setLoading] = useState(true);
  const [regions, setRegions] = useState<Region[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<string>('all');
  const [topTenScores, setTopTenScores] = useState<number[]>([]);
  const lastUpdateDate = new Date().toLocaleDateString('zh-CN');
  const [personalStats, setPersonalStats] = useState<{
    rank: number;
    total: number;
    integralValue: number;
    percentage: string;
  } | null>(null);
  const [selectedGroup, setSelectedGroup] = useState('全部');

  // 获取区域列表
  useEffect(() => {
    const fetchRegions = async () => {
      try {
        console.log('开始获取区域列表...');
        const response = await authApi.getRegions();
        console.log('区域列表响应:', response);
        if (response.code === 200 && response.data) {
          console.log('设置区域数据:', response.data);
          setRegions(response.data);
        } else {
          console.error('获取区域列表失败:', response.message);
          message.error('获取区域列���失败');
        }
      } catch (error) {
        console.error('获取区域列表出错:', error);
      }
    };

    fetchRegions();
  }, []);

  // 获取排名数据
  useEffect(() => {
    const fetchRankings = async () => {
      try {
        const response = await authApi.getEmployeeRanking();
        if (response.code === 200 && response.data) {
          console.log('获取到的排名数据:', response.data);
          
          // 确保数据格式正确
          const formattedData = response.data.map(emp => ({
            ...emp,
            // 确保使用正确的属性名
            storename: emp.storeName|| '未知店铺',
            regionname: emp.regionName  || '未知区域',
            rank: emp.rank || 0,
            integralValue: emp.integralValue || 0
          }));

          setRankings(formattedData);
          
          // 根据选择的区域计算前十名分数
          const filteredData = selectedRegion === 'all' 
            ? formattedData 
            : formattedData.filter(emp => emp.regionname === selectedRegion);
          
          const topScores = filteredData
            .slice(0, 10)
            .map(emp => emp.integralValue);
          
          setTopTenScores(topScores);
        } else {
          message.error(response.message || '获取排名失��');
        }
      } catch (error) {
        console.error('Failed to fetch rankings:', error);
        message.error('获取排名失败，请稍后重试');
      } finally {
        setLoading(false);
      }
    };

    fetchRankings();
  }, [selectedRegion]);

  // 过滤显示的排名数据
  const filteredRankings = useMemo(() => {
    console.log('过滤排名数据:', { 
      selectedRegion, 
      rankings,
      regionNames: rankings.map(r => r.regionName)
    });
    
    if (selectedRegion === 'all') {
      return rankings;
    }
    
    const filtered = rankings.filter(emp => {
      const employeeRegion = emp.regionName;
      const selected = selectedRegion;
      
      console.log('区域比较:', {
        employeeRegion,
        selected,
        employee: emp
      });
      
      return employeeRegion === selected;
    });
    
    console.log('过滤后的数据:', filtered);
    return filtered;
  }, [rankings, selectedRegion]);

  const formatPoints = (points: number | undefined, rank: number, selectedGroup: string): JSX.Element => {
    const value = Number(points);
    
    // 前10名显示实际积分（根据选中的组别）
    if (rank <= 10) {
      return (
        <div>
          <span style={{ 
            fontSize: '18px', 
            fontWeight: 'bold', 
            color: rank <= 3 ? '#1890ff' : '#666' 
          }}>
            {value.toLocaleString()}
          </span>
          {rank === 1 && (
            <div style={{ fontSize: '12px', color: '#999', marginTop: '4px' }}>
              {selectedGroup !== '全部' ? `${selectedGroup}第1名` : '总排名第1名'}
            </div>
          )}
        </div>
      );
    }
    
    // 11-15名显示遮罩和差距
    if (rank <= 15) {
      const lastTopTenScore = topTenScores[9] || 0;
      const difference = Math.max(0, lastTopTenScore - value);
      const maskedValue = '*'.repeat(Math.min(value.toString().length, 10));
      
      return (
        <div>
          <MaskedValue>{maskedValue}</MaskedValue>
          <div style={{ fontSize: '12px', color: '#999', marginTop: '4px' }}>
            距前10名还差 {difference.toLocaleString()} 元宝
          </div>
        </div>
      );
    }
    
    // 15名以后只显示遮罩
    const maskedValue = '*'.repeat(Math.min(value.toString().length, 10));
    return <MaskedValue>{maskedValue}</MaskedValue>;
  };

  const getAvatarUrl = async (employee: RankingEmployee): Promise<string> => {
    if (!employee.profile) {
      return '/images/default-avatar.svg';
    }

    try {
      const response = await authApi.getAvatar(employee.id_number);
      if (response.code === 200 && response.data?.url) {
        return response.data.url;
      }
      return '/images/default-avatar.svg';
    } catch (error) {
      console.error('Failed to fetch avatar:', error);
      return '/images/default-avatar.svg';
    }
  };

  const getNameInitial = (name: string): string => {
    return name ? name.charAt(0) : '?';
  };

  const renderTopTenScores = () => {
    if (!topTenScores || !topTenScores.length) return null;

    return (
      <div style={{ marginTop: '16px', fontSize: '12px', opacity: 0.9 }}>
        <div>前十名元宝：</div>
        <div style={{ 
          display: 'flex', 
          flexWrap: 'wrap', 
          gap: '8px', 
          marginTop: '4px' 
        }}>
          {topTenScores.map((score, index) => {
            if (typeof score !== 'number' || isNaN(score)) return null;
            return (
              <span key={index} style={{ whiteSpace: 'nowrap' }}>
                {index + 1}. {score.toLocaleString()}元宝
              </span>
            );
          })}
        </div>
      </div>
    );
  };

  // 获取个人排名数据
  useEffect(() => {
    const fetchPersonalRank = async () => {
      const employeeStr = localStorage.getItem('employee');
      if (employeeStr) {
        const employee = JSON.parse(employeeStr);
        try {
          const response = await authApi.getPersonalRank(employee.idNumber);
          if (response.code === 200 && response.data) {
            setPersonalStats(response.data);
          }
        } catch (error) {
          console.error('Failed to fetch personal rank:', error);
        }
      }
    };

    fetchPersonalRank();
  }, []);

  console.log('Current Rankings:', rankings);
  console.log('Current Top Ten Scores:', topTenScores);

  // 添加组别过滤和重新计算排名的函数
  const getFilteredRankings = () => {
    if (selectedGroup === '全部') {
      return rankings;
    }
    
    // 过滤出选中组别的数据并重新计算排名
    const filteredData = rankings.filter(emp => emp.regionName === selectedGroup);
    return filteredData.map((emp, index) => ({
      ...emp,
      rank: index + 1  // 重新计算组内排名
    }));
  };

  if (loading) {
    return (
      <PageContainer>
        <HeaderCard>
          <HeaderTitle>
            <TrophyOutlined />
            <h1>2024年终挑战赛排行榜</h1>
          </HeaderTitle>
          <UpdateTime>
            <CalendarOutlined />
            <span>加载中...</span>
          </UpdateTime>
        </HeaderCard>
        <LoadingContainer>
          <Spin 
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            tip="加载中..."
          />
        </LoadingContainer>
        <TabBar />
      </PageContainer>
    );
  }

  if (!rankings || !rankings.length) {
    console.log('No rankings data available');
    return (
      <PageContainer>
        <HeaderCard>
          <HeaderTitle>
            <TrophyOutlined />
            <h1>2024年终挑战赛排行榜</h1>
          </HeaderTitle>
          <UpdateTime>
            <CalendarOutlined />
            <span>最后更新：{lastUpdateDate}</span>
          </UpdateTime>
        </HeaderCard>
        <div style={{ 
          textAlign: 'center', 
          padding: '40px 20px',
          background: 'white',
          borderRadius: '12px',
          margin: '16px 0'
        }}>
          暂无排名数据
        </div>
        <TabBar />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <PersonalRankCard>
        <RankTitle>当前排名</RankTitle>
        <RankNumber>第 {personalStats?.rank || '-'} 名</RankNumber>
        <StatsGrid>
          <StatCard>
            <div className="icon"><TeamOutlined /></div>
            <div className="value">{personalStats?.total || 0}</div>
            <div className="label">总参与人数</div>
          </StatCard>
          <StatCard>
            <div className="icon"><TrophyOutlined /></div>
            <div className="value">{personalStats?.integralValue?.toLocaleString() || 0}</div>
            <div className="label">当前元宝</div>
          </StatCard>
          <StatCard>
            <div className="icon"><RiseOutlined /></div>
            <div className="value">{personalStats?.percentage || '0%'}</div>
            <div className="label">超越比例</div>
          </StatCard>
        </StatsGrid>
      </PersonalRankCard>

      <HeaderCard>
        <HeaderTitle>
          <TrophyOutlined />
          <h1>2024年终挑战赛排行榜</h1>
        </HeaderTitle>
        
        {/* 添加组别选择器 */}
        <Select
          value={selectedGroup}
          onChange={setSelectedGroup}
          style={{ 
            width: 120, 
            marginTop: 16,
            marginBottom: 16 
          }}
        >
          <Select.Option value="全部">全部</Select.Option>
          <Select.Option value="A组">红色战区</Select.Option>
          <Select.Option value="B组">蓝色战区</Select.Option>
          <Select.Option value="C组">紫色战区</Select.Option>
        </Select>

        <UpdateTime>
          <CalendarOutlined />
          <span>最后更新：{lastUpdateDate}</span>
        </UpdateTime>
        {renderTopTenScores()}
      </HeaderCard>

      {/* 使用过滤后的数据渲染列表 */}
      {getFilteredRankings().map((employee, index) => (
        <RankingItem
          key={employee.id_number}
          employee={employee}
          index={index}
          formatPoints={formatPoints}
        />
      ))}

      <TabBar />
    </PageContainer>
  );
};