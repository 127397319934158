import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { HomeOutlined, TrophyOutlined, PayCircleOutlined, UserOutlined } from '@ant-design/icons';

const TabBarContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.06);
  z-index: 100;
`;

const TabItem = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: ${props => props.active ? '#1890ff' : '#666'};
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s;

  .icon {
    font-size: 20px;
    margin-bottom: 2px;
  }
`;

export const TabBar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const tabs = [
    { key: 'dashboard', path: '/dashboard', icon: <HomeOutlined className="icon" />, label: '首页' },
    { key: 'ranking', path: '/ranking', icon: <TrophyOutlined className="icon" />, label: '排行榜' },
    { key: 'bonus', path: '/bonus', icon: <PayCircleOutlined className="icon" />, label: '奖金预估' },
    { key: 'profile', path: '/profile', icon: <UserOutlined className="icon" />, label: '我的' }
  ];

  return (
    <TabBarContainer>
      {tabs.map(tab => (
        <TabItem
          key={tab.key}
          active={location.pathname === tab.path}
          onClick={() => navigate(tab.path)}
        >
          {tab.icon}
          <span>{tab.label}</span>
        </TabItem>
      ))}
    </TabBarContainer>
  );
};