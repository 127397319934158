import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { IdcardOutlined, LockOutlined } from '@ant-design/icons';
import { authApi } from '../api/auth';

const LoginContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #1890ff 0%, #096dd9 100%);
  padding: 20px;
`;

const LoginCard = styled.div`
  background: white;
  padding: 32px 24px;
  border-radius: 16px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
`;

const LogoContainer = styled.div`
  text-align: center;
  margin-bottom: 40px;

  .logo {
    width: 240px;
    height: 160px;
    margin-bottom: 24px;
    filter: brightness(0) invert(1);
  }

  .subtitle {
    font-size: 24px;
    color: white;
    font-family: "STXingkai", "华文行楷", serif;
    letter-spacing: 2px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    display: inline-block;
    padding: 0 20px;
    margin-top: 8px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      width: 30px;
      height: 2px;
      background: rgba(255, 255, 255, 0.8);
      transform: translateY(-50%);
    }

    &::before {
      left: -20px;
    }

    &::after {
      right: -20px;
    }

    animation: glow 2s ease-in-out infinite alternate;
    @keyframes glow {
      from {
        text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #1890ff;
      }
      to {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #1890ff;
      }
    }
  }

  @media (max-width: 480px) {
    .logo {
      width: 200px;
      height: 133px;
    }

    .subtitle {
      font-size: 20px;
    }
  }
`;

const LoginTitle = styled.h2`
  color: #1890ff;
  text-align: center;
  font-size: 20px;
  margin-bottom: 24px;
  font-weight: bold;
`;

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 24px;
  }

  .ant-input-affix-wrapper {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #e8e8e8;
    
    &:hover, &:focus {
      border-color: #1890ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
    }

    .ant-input {
      font-size: 16px;
    }

    .anticon {
      color: #bfbfbf;
    }
  }

  .ant-input-password {
    padding: 12px;
  }

  .login-button {
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px;
    background: #1890ff;
    margin-top: 8px;
    
    &:hover {
      background: #40a9ff;
    }

    &:active {
      background: #096dd9;
    }
  }
` as typeof Form;

const RequiredLabel = styled.span`
  &::before {
    content: '* ';
    color: #ff4d4f;
  }
`;

// 添加登录表单的类型定义
interface LoginForm {
  idNumber: string;
  password: string;
}

export const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleSubmit = async (values: LoginForm) => {
    try {
      setLoading(true);
      const response = await authApi.login(values);
      
      if (response.code === 200 && response.data) {
        // 保存用户信息到localStorage
        localStorage.setItem('employee', JSON.stringify(response.data));
        message.success('登录成功');
        navigate('/dashboard');
      } else {
        message.error(response.message || '登录失败，请检查工号和密码');
      }
    } catch (error) {
      console.error('登录出错:', error);
      message.error('登录失败，请稍后重试');
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoginContainer>
      <LogoContainer>
        <img 
          src="/images/logo.png"
          alt="珠宝通" 
          className="logo"
        />
        <div className="subtitle">2024年终挑战赛</div>
      </LogoContainer>

      <LoginCard>
        <LoginTitle>顾问登录</LoginTitle>
        <StyledForm<LoginForm>
          form={form}
          onFinish={handleSubmit}
          size="large"
        >
          <Form.Item
            name="idNumber"
            rules={[
              { required: true, message: '请输入工号' }
            ]}
          >
            <Input
              prefix={<IdcardOutlined />}
              placeholder="请输入工号"
              autoComplete="off"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              { required: true, message: '请输入密码' }
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="请输入密码"
              autoComplete="off"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={loading}
              className="login-button"
            >
              登录
            </Button>
          </Form.Item>
        </StyledForm>
      </LoginCard>
    </LoginContainer>
  );
}; 