import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Card, Progress, message, Button, Modal } from 'antd';
import { TrophyOutlined, LogoutOutlined, LockOutlined } from '@ant-design/icons';
import { TabBar } from '../components/TabBar';
import { authApi } from '../api/auth';
import type { EmployeeStats } from '../api/auth';  // 确保从 auth.ts 导出这个类型
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import { useNavigate } from 'react-router-dom';
import { EditPasswordModal } from '../components/EditPasswordModal';

// 样式定义
const PageContainer = styled.div`
  padding: 16px;
  padding-bottom: 60px;
  background-color: #f5f5f5;
  min-height: 100vh;
`;

const RankCard = styled.div`
  background: linear-gradient(135deg, #1890ff 0%, #096dd9 100%);
  border-radius: 12px;
  padding: 20px;
  color: white;
  margin-bottom: 16px;
`;

const RankTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .icon {
    font-size: 24px;
    margin-right: 8px;
  }

  h2 {
    font-size: 20px;
    margin: 0;
    color: white;
  }
`;

const RewardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 24px;
  margin-top: 16px;
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
  }
`;

const RewardCard = styled(Card)<{ isFlipped: boolean }>`
  perspective: 1000px;
  background: transparent;
  margin-bottom: 16px;
  cursor: pointer;
  height: 180px;
  width: 100%;
  position: relative;

  .card-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    transform: ${props => props.isFlipped ? 'rotateY(180deg)' : 'rotateY(0)'};
  }

  .card-front,
  .card-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background: white;
  }

  .card-front {
    background: linear-gradient(135deg, #fff 0%, #f0f2f5 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    z-index: 1;
  }

  .card-back {
    background: #f5f5f5;
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    padding: 16px;
    z-index: 0;

    .reward-content {
      background: white;
      border-radius: 8px;
      padding: 12px;
      height: 100%;
      display: flex;
      flex-direction: column;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
      overflow: hidden;
    }

    .reward-title {
      font-size: 14px;
      font-weight: bold;
      color: #1890ff;
      margin-bottom: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid #f0f0f0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .reward-description {
      flex: 1;
      font-size: 12px;
      line-height: 1.5;
      color: #666;
      overflow-y: auto;
      padding-right: 4px;
      word-break: break-word;

      &::-webkit-scrollbar {
        width: 4px;
      }
      
      &::-webkit-scrollbar-track {
        background: #f5f5f5;
        border-radius: 2px;
      }
      
      &::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 2px;
      }
      
      &::-webkit-scrollbar-thumb:hover {
        background: #999;
      }
    }
  }

  .reward-icon {
    font-size: 36px;
    margin-bottom: 12px;
  }

  .reward-title {
    margin: 0 0 8px;
    color: #1890ff;
    font-size: 16px;
    font-weight: bold;
  }

  .reward-description {
    color: #666;
    font-size: 14px;
  }

  &:hover {
    transform: translateY(-2px);
    transition: transform 0.2s ease;
  }
`;

interface RewardDetail {
  icon: string;
  title: string;
  description: string;
  frontDescription: string;
}

// 修改段位选择条样式
const RankSelector = styled.div`
  position: relative;
  background: white;
  padding: 12px;
  border-radius: 12px;
  margin-bottom: 20px;
  overflow: hidden;

  .swiper-container {
    width: 100%;
    padding: 0 8px;
  }

  .swiper-slide {
    width: auto !important;  // 强制使用自动宽度
    display: flex;
    justify-content: center;
  }

  .swiper-wrapper {
    display: flex;
    justify-content: center;
  }
`;

const RankButton = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 8px 16px;
  border: none;
  background: ${props => props.active ? '#1890ff' : 'transparent'};
  color: ${props => props.active ? 'white' : '#666'};
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s;
  white-space: nowrap;
  min-width: 80px;  // 减小最小宽度
  height: 36px;  // 稍微减小高度
  font-size: 14px;
  margin: 0 4px;
  touch-action: manipulation;  // 优��触摸操作

  &:active {
    transform: scale(0.98);
  }

  .rank-icon {
    font-size: 16px;  // 稍微减小图标大小
  }

  ${props => props.active && `
    box-shadow: 0 2px 8px rgba(24, 144, 255, 0.35);
    font-weight: 500;
  `}
`;

// 定义各组段位区间（单位：万）
const RANK_THRESHOLDS = {
  'A': {
    '无段位': { min: 0, max: 3 },
    '青铜': { min: 3, max: 5 },
    '白银': { min: 5, max: 8 },
    '黄金': { min: 8, max: 12 },
    '钻石': { min: 12, max: 18 },
    '王者': { min: 18, max: Infinity }
  },
  'B': {
    '无段位': { min: 0, max: 2.5 },
    '青铜': { min: 2.5, max: 4 },
    '白银': { min: 4, max: 6 },
    '黄金': { min: 6, max: 8 },
    '钻石': { min: 8, max: 12 },
    '王者': { min: 12, max: Infinity }
  },
  'C': {
    '无段位': { min: 0, max: 2 },
    '青铜': { min: 2, max: 3 },
    '白银': { min: 3, max: 5 },
    '黄金': { min: 5, max: 7 },
    '钻石': { min: 7, max: 10 },
    '王者': { min: 10, max: Infinity }
  }
};

// 修改段位图标的定义，添加无段位
const RANKS: Array<{ name: DisplayRankName; icon: string; color: string }> = [
  { name: '无段位', icon: '🔰', color: '#A9A9A9' },
  { name: '青铜', icon: '🥉', color: '#CD7F32' },
  { name: '白银', icon: '🥈', color: '#C0C0C0' },
  { name: '黄金', icon: '🥇', color: '#FFD700' },
  { name: '钻石', icon: '💎', color: '#B9F2FF' },
  { name: '王者', icon: '👑', color: '#FF4D4F' }
];

// 更新奖励数据
const RANK_REWARDS: Record<string, RewardDetail[]> = {
  '青铜': [
    {
      icon: '🎫',
      title: '内购券',
      frontDescription: '200元内购券',
      description: '您将获得200元内购券，可用于购买公司指定商品，享受专属优惠。'
    },
    {
      icon: '🎲',
      title: '抽奖券',
      frontDescription: '1张抽奖券',
      description: '年会抽奖活动中，您将获得1张抽奖券的参与机会，祝您好运！'
    }
  ],
  '白银': [
    {
      icon: '🏖️',
      title: '休假福利',
      frontDescription: '休假1天',
      description: '在规定期限内您可以享受1天的带薪休假，让您在工作之余得到充分休息。（使用期限为2025年4月1日至6月30日）'
    },
    {
      icon: '💰',
      title: '现金奖励',
      frontDescription: '现金100元',
      description: '恭喜您达到白银段位！公司将为您发放100元现金奖励，感谢您的持续进步。'
    },
    {
      icon: '🎫',
      title: '内购券',
      frontDescription: '500元内购券',
      description: '恭喜您将获得500元内购券，可用于购买公司指定商品，享受更多优惠。'
    },
    {
      icon: '🎲',
      title: '抽奖券',
      frontDescription: '2张抽奖券',
      description: '年会抽奖活动中，您将获得2张抽奖券，增加中奖机会！'
    },
    {
      icon: '❓',
      title: '白银专属礼',
      frontDescription: '神秘礼品',
      description: '您将获得一份神秘实物礼品，敬请期待！'
    }
  ],
  '黄金': [
    {
      icon: '🏖️',
      title: '休假福利',
      frontDescription: '休假2天',
      description: '在规定期限内您可以享受2的带薪休假，让您能安排短途旅行或充分休息。（使用期限为2025年4月1日至6月30日）'
    },
    {
      icon: '💰',
      title: '现金奖励',
      frontDescription: '现金200元',
      description: '恭喜您达到黄金段位！公司将为您发放200元现金奖励，感谢您的优秀表现。'
    },
    {
      icon: '🎫',
      title: '内购券',
      frontDescription: '1000元内购券',
      description: '您将获得1000元内购券，可用于购买公司指定商品，享受更多优惠。'
    },
    {
      icon: '🎲',
      title: '抽奖券',
      frontDescription: '3张抽奖券',
      description: '年会抽奖活动中，您将获得3张抽奖券，大大增加奖机会！'
    },
    {
      icon: '❓',
      title: '黄金专属礼',
      frontDescription: '黄金专属礼',
      description: '您将获得一份神秘实物礼品，敬请期待！'
    }
  ],
  '钻石': [
    {
      icon: '🏖️',
      title: '休假福利',
      frontDescription: '休假3天',
      description: '在规定期限内您可以享受3天的带薪休假，让您能安排一次完的旅行。（使用期限为2025年4月1日至6月30日）'
    },
    {
      icon: '💰',
      title: '现金奖励',
      frontDescription: '现金500元',
      description: '恭喜您达到钻石段位！公司将为您发放500现金奖励，感谢您的卓越献。'
    },
    {
      icon: '🎫',
      title: '内购券',
      frontDescription: '2000元内购券',
      description: '您将获得2000元内购券，可用于购买公司指定商品，享受更多优惠。'
    },
    {
      icon: '🎲',
      title: '抽奖券',
      frontDescription: '5张抽奖券',
      description: '年会抽奖活动中，您将获得5张抽奖券，中奖机会大大提升！'
    },
    {
      icon: '❓',
      title: '钻石专属礼',
      frontDescription: '神秘礼品',
      description: '您将获得一份神秘实物礼品，敬请期待！'
    }
  ],
  '王者': [
    {
      icon: '🏖️',
      title: '休假福利',
      frontDescription: '休假4天',
      description: '在规定期限内您可以享受4天的带薪休假，尽情放松身心。（使用期限为2025年4月1日至6月30日）'
    },
    {
      icon: '💰',
      title: '现金奖励',
      frontDescription: '现金1000元',
      description: '恭喜您达到王者段位！公司将为您发放1000元现金奖励感谢您的杰出表现。'
    },
    {
      icon: '🎫',
      title: '内购券',
      frontDescription: '3000元内购券',
      description: '您将获得3000元内购券，享受更多优商品和专属优惠。'
    },
    {
      icon: '🎲',
      title: '抽奖券',
      frontDescription: '10张抽奖券',
      description: '年会抽奖活动中，您将获得10张抽奖券，赢取大奖的机会大大提升！'
    },
    {
      icon: '❓',
      title: '王者专属礼',
      frontDescription: '神秘礼品',
      description: '您将获得一份神秘实物礼品，敬请期待！'
    },
    {
      icon: '✈️',
      title: '旅游产品',
      frontDescription: '神秘旅游大礼',
      description: '您将获得一份神秘旅游大礼，敬请期待！（各组别第一可以享用）'
    }
  ]
};

// 添加或修改以下样式组件
const UserHeader = styled.div`
  background: linear-gradient(135deg, #1890ff 0%, #096dd9 100%);
  padding: 20px;
  border-radius: 12px;
  color: white;
  margin-bottom: 20px;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 12px;
  border: 2px solid rgba(255, 255, 255, 0.8);
`;

const UserDetails = styled.div`
  flex: 1;
`;

const UserName = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 8px;

  .action-buttons {
    display: flex;
    gap: 8px;
    margin-left: auto;
  }

  .action-button {
    color: white;
    padding: 0 8px;
    font-size: 14px;
    opacity: 0.9;
    display: flex;
    align-items: center;
    gap: 4px;
    
    &:hover {
      opacity: 1;
      color: white;
    }

    &.danger {
      &:hover {
        color: #ff4d4f;
      }
    }

    .anticon {
      font-size: 14px;
    }
  }
`;

const UserMeta = styled.div`
  font-size: 14px;
  opacity: 0.9;
`;

const PointsInfo = styled.div`
  margin-top: 16px;
`;

const PointsLabel = styled.div`
  font-size: 14px;
  opacity: 0.9;
  margin-bottom: 8px;
`;

const PointsValue = styled.div`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const ProgressBar = styled(Progress)`
  .ant-progress-bg {
    background: white;
  }
`;

// 1. 首先在文件顶部添加 GROUP_RANK_POINTS 常量
const GROUP_RANK_POINTS = {
  'A': 25000,  // A组每个段位需要2.5w积分
  'B': 20000,  // B组每个段位需要2w积分
  'C': 15000   // C组每个段位需要1.5w积分
};

// 定义段位类型
type RankName = '青铜' | '白银' | '黄金' | '钻石' | '王者';
type GroupName = 'A' | 'B' | 'C';
type DisplayRankName = RankName | '无段位';

// 添加一个函数来解析XML并获取图片URL
const extractImageUrlFromXml = (xmlString: string): string | null => {
  try {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");
    // 假设XML中的图片URL在某个特定标签中，比如 <url> 或 <image>
    // 需要根据实际的XML结构来调整这个选择器
    const urlNode = xmlDoc.querySelector('url, image');
    return urlNode ? urlNode.textContent : null;
  } catch (error) {
    console.error('解析XML失败:', error);
    return null;
  }
};

// 修改组别名称的映射
const GROUP_NAMES = {
  'A': '红色战区',
  'B': '蓝色战区',
  'C': '紫色战区'
};

// 修改组别颜色
const GROUP_COLORS = {
  'A': '#ff4d4f',  // 红色
  'B': '#1890ff',  // 蓝色
  'C': '#722ed1'   // 紫色
};

export const Profile: React.FC = () => {
  const [currentRank, setCurrentRank] = useState<DisplayRankName>('无段位');
  const [nextRank, setNextRank] = useState<RankName | '已达最高段位'>('青铜');
  const [progress, setProgress] = useState(0);
  const [flippedCard, setFlippedCard] = useState<string | null>(null);
  const [userGroup, setUserGroup] = useState('C');
  const [points, setPoints] = useState(0);
  const [selectedRank, setSelectedRank] = useState<DisplayRankName>('青铜');
  const [employeeStats, setEmployeeStats] = useState<EmployeeStats | null>(null);
  const navigate = useNavigate();
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState<string>('/images/default-avatar.svg');

  useEffect(() => {
    // 获取员工积分统计
    const fetchEmployeeStats = async () => {
      const employeeStr = localStorage.getItem('employee');
      if (employeeStr) {
        const employee = JSON.parse(employeeStr);
        try {
          const response = await authApi.getEmployeeStats(employee.idNumber);
          console.log('获取到的积分统计:', response);
          
          if (response.code === 200 && response.data) {
            const data = response.data;
            console.log('处理后的积分数据:', data);
            
            // 直接使用返回的数据更新状态
            setEmployeeStats(data);
            setPoints(data.integralValue);
            
            // 从 regionName 中提取组（去掉"组"字）
            const group = data.regionName.replace(/组$/, '');
            console.log('设置用户组别:', group);
            setUserGroup(group);
            
            // 计算当前段
            const calculatedRank = calculateRank(data.integralValue, group);
            console.log('计算的当前段位:', calculatedRank);
            setCurrentRank(calculatedRank);
            setSelectedRank(calculatedRank); // 设置默认选中的段位为当前段位
            
            // 更新进度条
            const pointsInWan = data.integralValue / 10000;
            const thresholds = RANK_THRESHOLDS[group as GroupName];
            if (calculatedRank !== '无段位') {
              const rankInfo = thresholds[calculatedRank as RankName];
              if (rankInfo) {
                const progress = ((pointsInWan - rankInfo.min) / (rankInfo.max - rankInfo.min)) * 100;
                setProgress(Math.min(Math.max(progress, 0), 100));
              }
            }
          } else {
            console.error('获取积分失败:', response);
            message.error('获取积分信息失败');
          }
        } catch (error) {
          console.error('获取积分信息出错:', error);
          message.error('获取积分信息失败，请稍后重试');
        }
      }
    };

    fetchEmployeeStats();
  }, []);

  // 修改计算段位的逻辑
  const calculateRank = (points: number, group: string): DisplayRankName => {
    const pointsInWan = points / 10000;
    const thresholds = RANK_THRESHOLDS[group as GroupName];
    
    // 遍历所有段位区间找到当前段位
    for (const [rank, { min, max }] of Object.entries(thresholds)) {
      if (pointsInWan >= min && pointsInWan < max) {
        return rank as DisplayRankName;
      }
    }
    
    return '王者'; // 如果都不在范围内，说明达到王者段位
  };

  // 修改计算下一个段位所需积分的函数
  const calculateNextRankPoints = (currentPoints: number, group: string, currentRank: string) => {
    const pointsInWan = currentPoints / 10000;
    const thresholds = RANK_THRESHOLDS[group as GroupName];
    
    // 如果是王者段位，返回0
    if (currentRank === '王者') {
      return 0;
    }
    
    // 找到当前段位的下一个段位
    const ranks = Object.keys(thresholds) as Array<keyof typeof thresholds>;
    const currentIndex = ranks.indexOf(currentRank as keyof typeof thresholds);
    const nextRank = ranks[currentIndex + 1];
    
    if (nextRank) {
      const rankInfo = thresholds[nextRank];
      return (rankInfo.min - pointsInWan).toFixed(1);
    }
    
    return 0;
  };

  useEffect(() => {
    const rank = calculateRank(points, userGroup as GroupName);
    setCurrentRank(rank as DisplayRankName);
    
    // 设置下一个段位
    const ranks: RankName[] = ['青铜', '白银', '黄金', '钻石', '王者'];
    const currentIndex = ranks.indexOf(rank as RankName);
    const next = currentIndex < ranks.length - 1 ? ranks[currentIndex + 1] : '已达最高段位';
    setNextRank(next);
    
    // 更新进度条
    const thresholds = RANK_THRESHOLDS[userGroup as GroupName];
    if (rank !== '无段位') {
      const rankInfo = thresholds[rank as RankName];
      if (rankInfo) {
        const { min, max } = rankInfo;
        const pointsInWan = points / 10000;
        const progress = ((pointsInWan - min) / (max - min)) * 100;
        setProgress(Math.min(Math.max(progress, 0), 100));
      }
    }
  }, [points, userGroup]);

  const handleCardFlip = (rank: string) => {
    setFlippedCard(flippedCard === rank ? null : rank);
  };

  // 从localStorage获取户信息
  const employeeData = JSON.parse(localStorage.getItem('employee') || '{}');

  // 计算选中段位的进度和所需积分
  const calculateSelectedRankProgress = (selectedRank: string) => {
    const pointsInWan = points / 10000;
    const thresholds = RANK_THRESHOLDS[userGroup as keyof typeof RANK_THRESHOLDS];
    const rankInfo = thresholds[selectedRank as keyof typeof thresholds];

    if (!rankInfo) return { progress: 0, pointsNeeded: 0 };

    // 如果积分已超过该段位
    if (pointsInWan >= rankInfo.max) {
      return { progress: 100, pointsNeeded: 0 };
    }

    // 如果积分低于该段位最小值
    if (pointsInWan < rankInfo.min) {
      const pointsNeeded = (rankInfo.min * 10000) - points;
      return { progress: 0, pointsNeeded };
    }

    // 在段位范内
    const progress = ((pointsInWan - rankInfo.min) / (rankInfo.max - rankInfo.min)) * 100;
    const pointsNeeded = (rankInfo.max * 10000) - points;

    return {
      progress: Math.min(Math.max(progress, 0), 100),
      pointsNeeded: Math.max(pointsNeeded, 0)
    };
  };

  // 修改渲染进度信息的逻辑
  const renderProgressInfo = (): React.ReactNode => {
    const pointsInWan = points / 10000;
    const thresholds = RANK_THRESHOLDS[userGroup as GroupName];
    
    if (selectedRank === '无段位') {
      return (
        <>
          <div>暂无段位</div>
          <Progress percent={0} showInfo={false} />
        </>
      );
    }

    const rankInfo = thresholds[selectedRank as RankName];

    if (!rankInfo) {
      return (
        <>
          <div>数据加载中...</div>
          <Progress percent={0} showInfo={false} />
        </>
      );
    }

    let progressText = '';
    let progress = 0;

    // 判断当前积分是否已经超过选中段位的最低要求
    if (pointsInWan >= rankInfo.min) {
      if (pointsInWan >= rankInfo.max) {
        // 已超过该段位
        progressText = `您已达到${selectedRank}段位`;
        progress = 100;
      } else {
        // 在该段位范围内
        const pointsNeeded = Math.ceil((rankInfo.max * 10000) - points);
        progressText = pointsNeeded > 0 
          ? `距离${nextRank}还需：${pointsNeeded.toLocaleString()}蓝钻`
          : `您已达到${selectedRank}段位要求`;
        progress = ((pointsInWan - rankInfo.min) / (rankInfo.max - rankInfo.min)) * 100;
      }
    } else {
      // 未达到该段位
      const pointsNeeded = Math.ceil((rankInfo.min * 10000) - points);
      progressText = `距离${selectedRank}段位还需：${pointsNeeded.toLocaleString()}蓝钻`;
      progress = 0;
    }

    return (
      <>
        <div style={{ 
          marginBottom: '8px', 
          fontSize: '14px', 
          color: progress === 100 ? '#52c41a' : '#666'
        }}>
          {progressText}
        </div>
        <Progress 
          percent={progress} 
          showInfo={false} 
          strokeColor={{
            '0%': '#1890ff',
            '100%': progress === 100 ? '#52c41a' : (RANKS.find(r => r.name === selectedRank)?.color || '#1890ff')
          }}
          trailColor="rgba(0, 0, 0, 0.1)"
        />
      </>
    );
  };

  // 添加退出登录处理函数
  const handleLogout = () => {
    Modal.confirm({
      title: '确认退出',
      content: '您确定要退出登录吗？',
      okText: '确认',
      cancelText: '取消',
      okButtonProps: {
        danger: true
      },
      onOk: () => {
        // 清除本地存储的用户信息和token
        localStorage.removeItem('employee');
        localStorage.removeItem('token');
        // 显示退出成功提示
        message.success('退出成功');
        // 跳转到登录页
        navigate('/login');
      }
    });
  };

  // 在获取头像的函数中使用
  const fetchAvatar = async () => {
    try {
      const response = await authApi.getAvatar(employeeData.idNumber);
      if (response.code === 200 && response.data?.url) {
        // 直接使用返回的URL
        setAvatarUrl(response.data.url);
      } else {
        setAvatarUrl('/images/default-avatar.svg');
      }
    } catch (error) {
      console.error('获取头像失败:', error);
      setAvatarUrl('/images/default-avatar.svg');
    }
  };

  // 在组件挂载时获取头像
  useEffect(() => {
    if (employeeData.idNumber) {
      fetchAvatar();
    }
  }, [employeeData.idNumber]);

  return (
    <PageContainer>
      <UserHeader>
        <UserInfo>
          <Avatar 
            src={avatarUrl} 
            alt="用户头像"
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.src = '/images/default-avatar.svg';
            }}
          />
          <UserDetails>
            <UserName>
              {employeeData.username}
              <div className="action-buttons">
                <Button 
                  type="text" 
                  size="small" 
                  className="action-button"
                  icon={<LockOutlined />}
                  onClick={() => setShowPasswordModal(true)}
                >
                  修改密码
                </Button>
                <Button 
                  type="text" 
                  size="small" 
                  className="action-button danger"
                  icon={<LogoutOutlined />}
                  onClick={handleLogout}
                >
                  退出登录
                </Button>
              </div>
            </UserName>
            <UserMeta>
              {employeeData.storeName} · {GROUP_NAMES[employeeData.regionName.replace(/组$/, '') as 'A' | 'B' | 'C'] || employeeData.regionName}
            </UserMeta>
          </UserDetails>
        </UserInfo>

        <PointsInfo>
          <PointsLabel>
            当前蓝钻（蓝钻每周上传后更新）
          </PointsLabel>
          <PointsValue>
            {employeeStats ? employeeStats.integralValue.toLocaleString() : '0'}蓝钻
          </PointsValue>
          <div style={{ marginBottom: '8px', fontSize: '14px' }}>
            <span style={{ 
              marginRight: '8px', 
              color: RANKS.find(r => r.name === currentRank)?.color || '#666'
            }}>
              当前段位：{currentRank}
            </span>
            {nextRank === '已达最高段位' 
              ? '恭喜达到最高段位！' 
              : `距离${nextRank}还需：${calculatePointsNeeded(points, nextRank as RankName, userGroup as GroupName)}万蓝钻`
            }
          </div>
          <ProgressBar 
            percent={progress} 
            showInfo={false} 
            strokeColor="white"
            trailColor="rgba(255, 255, 255, 0.3)"
          />
          {employeeStats && (
            <div style={{ fontSize: '12px', marginTop: '8px', opacity: 0.8 }}>
              {GROUP_NAMES[userGroup as 'A' | 'B' | 'C']}排名：前{(employeeStats.percentile * 100).toFixed(1)}%
              {' | '}元宝数：{employeeStats.totalYuanbao}
              {' | '}膨胀系数：{employeeStats.multiplier.toFixed(1)}x
            </div>
          )}
        </PointsInfo>
      </UserHeader>

      <RankSelector>
        <Swiper
          modules={[FreeMode]}
          slidesPerView="auto"
          freeMode={{
            enabled: true,
            momentum: true,
            momentumRatio: 0.8,
            momentumBounce: false
          }}
          spaceBetween={4}
          className="swiper-container"
          touchEventsTarget="container"
          slideToClickedSlide={true}
          centeredSlides={false}
          initialSlide={RANKS.findIndex(r => r.name === selectedRank)}
          breakpoints={{
            320: {
              slidesPerView: 'auto',
              spaceBetween: 4
            },
            768: {
              slidesPerView: 'auto',
              spaceBetween: 8
            }
          }}
        >
          {RANKS.map(rank => (
            <SwiperSlide key={rank.name}>
              <RankButton
                active={selectedRank === rank.name}
                onClick={() => setSelectedRank(rank.name as DisplayRankName)}
                style={{
                  background: selectedRank === rank.name 
                    ? `linear-gradient(135deg, #1890ff 0%, ${rank.color} 100%)`
                    : 'transparent'
                }}
              >
                <span className="rank-icon">{rank.icon}</span>
                <span>{rank.name}</span>
              </RankButton>
            </SwiperSlide>
          ))}
        </Swiper>
      </RankSelector>

      <div style={{ 
        background: 'white', 
        padding: '16px', 
        borderRadius: '12px',
        marginBottom: '20px'
      }}>
        {renderProgressInfo()}
      </div>

      <h2 style={{ margin: '24px 0 16px', color: '#1890ff' }}>
        {selectedRank}段位可获得的奖励
      </h2>
      
      <RewardGrid>
        {RANK_REWARDS[selectedRank]?.map((reward, index) => (
          <RewardCard
            key={index}
            isFlipped={flippedCard === `${selectedRank}-${index}`}
            onClick={() => handleCardFlip(`${selectedRank}-${index}`)}
          >
            <div className="card-inner">
              <div className="card-front">
                <span className="reward-icon">
                  {reward.icon}
                </span>
                <h3 className="reward-title">
                  {reward.title}
                </h3>
                <div className="reward-description">
                  {reward.frontDescription}
                </div>
              </div>
              <div className="card-back">
                <div className="reward-content">
                  <h3 className="reward-title">
                    {reward.title}
                  </h3>
                  <div className="reward-description">
                    {reward.description}
                  </div>
                </div>
              </div>
            </div>
          </RewardCard>
        ))}
      </RewardGrid>

      <EditPasswordModal
        visible={showPasswordModal}
        onClose={() => setShowPasswordModal(false)}
        idNumber={employeeData.idNumber}
      />
      <TabBar />
    </PageContainer>
  );
};

// 辅助函数：计算离下一段位所需积分
function calculatePointsNeeded(currentPoints: number, nextRank: RankName, group: GroupName): number {
  const pointsInWan = currentPoints / 10000;
  const thresholds = {
    'A': { '青铜': 3, '白银': 5, '黄金': 8, '钻石': 12, '王者': 18 },
    'B': { '青铜': 2.5, '白银': 4, '黄金': 6, '钻石': 8, '王者': 12 },
    'C': { '青铜': 2, '白银': 3, '黄金': 5, '钻石': 7, '王者': 10 }
  } as const;

  type ThresholdType = typeof thresholds[GroupName];
  const groupThresholds = thresholds[group];
  const threshold = groupThresholds[nextRank as keyof ThresholdType];
  return Number((Math.max(0, threshold - pointsInWan)).toFixed(1));
}